import consumer from "./consumer"

// turbolinks の読み込みが終わった後にidを取得しないと，エラーが出ます。
document.addEventListener('turbolinks:load', () => {

    // js.erb 内で使用できるように変数を定義しておく
    window.messageContainer = document.getElementById('message-container')

    let rid = "";

    // 以下のプログラムが他のページで動作しないようにしておく
    if (messageContainer === null) {
        return
    } else {
        rid = window.messageContainer.getAttribute("rid");
    }
    consumer.subscriptions.create({channel:"RoomChannel", rcode: rid}, {
        connected() {
        },

        disconnected() {
        },

        received(data) {
            // サーバー側から受け取ったHTMLを一番最後に加える
            $.get( "/messages/"+data['message_id'] )
            .done( function(res) { 
            	messageContainer.insertAdjacentHTML('beforeend', res)
                scrollToBottom()
                messageImage.value = '';
            } )
            .fail( function() { 
            	//alert("メッセージの取得ができませんでした。ブラウザをリロードしてください。")
            } )
            .always( function() { } )

        }
    })
    const documentElement = document.documentElement
    // js.erb 内でも使用できるように変数を決定
    window.messageContent = document.getElementById('message_content')
    window.messageImage = document.getElementById('message_image')
    //imageを空にする
    // 一番下まで移動する関数。js.erb 内でも使用できるように変数を決定
    window.scrollToBottom = () => {
        window.scroll(0, documentElement.scrollHeight)
    }

    // 最初にページ一番下へ移動させる
    scrollToBottom()

    const messageButton = document.getElementById('message-button')
    messageButton.classList.add('disabled')

    // 空欄でなければボタンを有効化，空欄なら無効化する関数
    const button_activation = () => {
        if (messageContent.value || messageImage.value ) {
            messageButton.classList.remove('disabled')
        } else {
            messageButton.classList.add('disabled')
        }
    }

    // フォームに入力した際の動作
    messageContent.addEventListener('input', () => {
        button_activation()
        changeLineCheck()
    })

    // 送信ボタンが押された時にボタンを無効化
    messageButton.addEventListener('click', () => {
        messageButton.classList.add('disabled')
        changeLineCount(1)
    })

    // フォームの最大行数を決定
    const maxLineCount = 10

    // 入力メッセージの行数を調べる関数
    const getLineCount = () => {
        return (messageContent.value + '\n').match(/\r?\n/g).length;
    }

    let lineCount = getLineCount()
    let newLineCount

    const changeLineCheck = () => {
        // 現在の入力行数を取得（ただし，最大の行数は maxLineCount とする）
        newLineCount = Math.min(getLineCount(), maxLineCount)
        // 以前の入力行数と異なる場合は変更する
        if (lineCount !== newLineCount) {
            changeLineCount(newLineCount)
        }
    }

    const changeLineCount = (newLineCount) => {
        // フォームの行数を変更
        messageContent.rows = lineCount = newLineCount
    }

    // 「この画像・メッセージは送信できませんでした」の表示
    $(function(){
        setTimeout("$('.declined').fadeOut('slow')", 5000);
      });
})