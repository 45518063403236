import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
  //js.erb 内で使用できるように変数を定義しておく
  window.chatContainer = document.getElementById('chat-container')
  let orid = "";
  if (window.chatContainer === null) {
    return
  } else {
    orid = window.chatContainer.getAttribute("orid");
    console.log(orid)
  }
  const appRoom = consumer.subscriptions.create({channel:"OfficialRoomChannel", rcode: orid}, {
    connected() {
      // Called when the subscription is ready for use on the server
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
  
    received(data) {
      // Called when there's incoming data on the websocket for this channel
      const chats = document.getElementById('chat-container');
      chats.insertAdjacentHTML('beforeend', data['official_chat']);
      scrollToBottom()
    },
  
    speak: function(official_chat, reader, file_name) {
      console.log(official_chat)
      return this.perform('speak', {official_chat: official_chat, reader: reader, file_name,file_name});
    }
  });

  const documentElement = document.documentElement
    // js.erb 内でも使用できるように変数を決定
    window.messageContent = document.getElementById('message_content')
    // 一番下まで移動する関数。js.erb 内でも使用できるように変数を決定
    window.scrollToBottom = () => {
        window.scroll(0, documentElement.scrollHeight)
    }

    // 最初にページ一番下へ移動させる
    scrollToBottom()
  
  $("#speaker_submit").click(function() {
    let speaker_val, speaker_image, has_image, has_content, file_name, reader;
    speaker_val = document.getElementById('speaker_val');
    speaker_image = $('#speaker_image');
    has_content = speaker_val.value.length > 0 ? true : false;
    has_image = speaker_image.get(0).files.length > 0 ? true : false;

    if (has_content | has_image) {
      if (has_image) {
        file_name = speaker_image.get(0).files[0].name;
        reader = new FileReader();
        reader.readAsDataURL(speaker_image.get(0).files[0]);
        reader.addEventListener("loadend", function() {
          return appRoom.speak(speaker_val.value, reader.result, file_name);
        });
      } else {
        appRoom.speak(speaker_val.value);
      }
    }
    speaker_val.value = '';
    speaker_image.val("");
    $('.FlexTextarea__dummy').text("");
  })
    // 「この画像・メッセージは送信できませんでした」の表示
    $(function(){
      setTimeout("$('.declined').fadeOut('slow')", 5000);
    });

})
