$(function(){

    var height=$(".mainHeader").outerHeight();
    var sub_height=$(".subHeader").outerHeight();
    if (sub_height){ 
        height = height + sub_height
    }
    $("body").css("margin-top", height);

    setTimeout("$('.notice').fadeOut('slow')", 2000);

    $('.areaButton').on("click", function(){
        const area = $(this).attr("id")
        $(".areaButton").not(this).removeClass("on")
        $('.region_box').not(`.${area}`).addClass("hide");
        $(this).toggleClass("on");
        $(`.${area}`).toggleClass("hide");
    });

    $('.slide-show').not('.slick-initialized').slick({
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 400
    });

    if (!(navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0 || navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0)) {
        // PC向けの記述
        // PCの場合はアコーディオンではなく最初から表示
        // $(".regions").slideToggle(600);
    }


    // 展開(記事・エリア一覧)
    $(".click_to_open").on('click', function(){
        $(this).siblings(".open_contents").slideToggle();
        $(this).children(".arrow").toggleClass("open");
    });

    $(".more_cities_btn").on('click', function(){
        $(".selectCities").css("height","auto");
        $(this).hide();
    });

    // イエローマーカー
    $(window).scroll(function (){
        $(".highliter").each(function(){
            var position = $(this).offset().top; //ページの一番上から要素までの距離を取得
            var scroll = $(window).scrollTop(); //スクロールの位置を取得
            var windowHeight = $(window).height(); //ウインドウの高さを取得
            if (scroll > position - windowHeight){ //スクロール位置が要素の位置を過ぎたとき
                $(this).addClass('active'); //クラス「active」を与える
            }
        });
    });


});

$(function(){
    var pagetop = $('#page_top');
    // ボタン非表示
    pagetop.hide();
  
    // 100px スクロールしたらボタン表示
    $(window).scroll(function () {
       if ($(this).scrollTop() > 100) {
            pagetop.fadeIn();
       } else {
            pagetop.fadeOut();
       }
    });
    pagetop.click(function () {
       $('body, html').animate({ scrollTop: 0 }, 500);
       return false;
    });
  });

  // 強制リロード
window.addEventListener('pageshow', function (event) {
    if (event.persisted) {
      // bfcache発動時の処理
      window.location.reload();
      console.log("safari用リロード")
    }
});

// LPのオーバーレイボタンページ最上部・最下部・フォーム上は非表示
$(function() {
  const overlayElement = $('.overlay_new_item');
  const newItemElement = $('#new_item');

  // newItemまたはoverlayが存在しない場合は、早期に処理を終了
  if (overlayElement.length === 0 || newItemElement.length === 0) {
      return;
  }

  const threshold = 100;

  function checkOverlay() {
      const newItemOffset = newItemElement.offset().top;
      const windowHeight = $(window).height();
      const scrollPosition = $(window).scrollTop();
      const documentHeight = $(document).height();

      if (scrollPosition <= threshold || 
          scrollPosition >= newItemOffset - windowHeight || 
          scrollPosition >= documentHeight - windowHeight - threshold) {
          overlayElement.fadeOut();
      } else {
          overlayElement.css('opacity', '');
          overlayElement.fadeIn();
      }
  }

  checkOverlay();
  $(window).scroll(checkOverlay);
});

  // 全角で入力された数字を半角に変換・文字入力拒否
$(document).ready(function() {
  $('input.half-width-input').on('input', function() {
    var inputVal = $(this).val();
    var convertedVal = zenkakuToHankakuNumbers(inputVal);
    $(this).val(convertedVal);
  });

  function zenkakuToHankakuNumbers(str) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    }).replace(/[^\d]/g, '');
  }  
});
  