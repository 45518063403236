$(document).on('click', 'check_to_fix', function () {
  // $(this).hide();
  $('.fix_price_form').removeClass("hide");
  $(this).toggleClass("checked");
  $(this).toggleClass("arrow");

});
$(document).on('click', '.checked', function () {
  // $(this).hide();
  $('.fix_price_form').addClass("hide");
  $(this).toggleClass("check_to_fix");

});

//戻るボタンで遷移するともう一度送信できてしまうのでリロード
window.onpageshow = function(event) {
	if (event.persisted) {
		 window.location.reload();
	}
};

$(function(){
    //TODO: idは複数使わないので、open,closeの役割を持たせるならclassの方がいいかも。

  // $('#modal-open-room').on('click',function(){
  //     $('.modal-room').fadeIn();
  //     return false;
  // });
  $('.modal-open-room').on('click',function(){
    $('.modal-room').fadeIn();
    return false;
  });
  $('.modal-open-room-two').on('click',function(){
    $('.modal-room-two').fadeIn();
    return false;
  });
  $('.modal-open-room-up').on('click',function(){
    $('.modal-room-up').fadeIn();
    return false;
  });
  $('.modal-open-room-down').on('click',function(){
    $('.modal-room-down').fadeIn();
    return false;
  });

  $('.show_loading').on('click', function(){
    // loading画面表示
    $('#loading').removeClass('hide');

  });

  // $('#modal-close-room').on('click',function(){
  //     $('.modal-room').fadeOut();
  //     return false;
  // });
  $('.modal-close-room').on('click',function(){
    $('.modal-room').fadeOut();
    return false;
  });
  $('.modal-close-room-up').on('click',function(){
    $('.modal-room-up').fadeOut();
    return false;
  });
  $('.modal-close-room-down').on('click',function(){
    $('.modal-room-down').fadeOut();
    return false;
  });

  // $('.modal-close-room-two').on('click',function(){
  //   $('.modal-room-two').fadeOut();
  //   return false;
  // });

  $('#modal-room-submit').on('click',function(){
    $('#quote_submit').click();
    $('.modal-room-up').fadeOut();
    return false;
  });
  // $('#modal-room-up-submit').on('click',function(){
  //   $('#quote_submit').click();
  //   $('.modal-room-up').fadeOut();
  //   return false;
  // });

  $('#modal-open-room-cancellation').on('click',function(){
    $('.modal-room-cancellation').fadeIn();
    return false;
  });
  $('.modal-open-room-cancellation').on('click',function(){
    $('.modal-room-cancellation').fadeIn();
    return false;
  });

  //TODO: idは複数使わないので、closeの役割を持たせるならclassの方がいいかも。
  $('#modal-close-room-cancellation').on('click',function(){
      $('.modal-room-cancellation').fadeOut();
      return false;
  });
  $('.modal-close-room-cancellation').on('click',function(){
    $('.modal-room-cancellation').fadeOut();
    return false;
  });

  //modal向け
  //見積もり金額
  var $room_quotation = $('#room_quotation');
  $('#quote_get_quotation').text($room_quotation.val());
  $room_quotation.on('keyup change',function(){
    let quotation_price = Number($room_quotation.val()).toLocaleString();
    $('#quote_get_quotation').text(quotation_price);
  });
  //備考
  var $room_quotation_description = $('#room_quotation_description');
  $('#quote_get_quotation_description').text($room_quotation_description.val())
  $room_quotation_description.on('keyup change',function(){
    $('#quote_get_quotation_description').html($room_quotation_description.val().split('\n').join('<br>'))
  });
  //訪問日時
  function get_schedule_text(date){
    if (!isNaN(date)){
      const year = date.getFullYear()
      const month = date.getMonth() + 1 
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const text = year + "年" + month + "月" + day + "日 " + hour + "時" + minute +"分"
      return text
    }else{
      return ""
    }
  
  }

  // function schedule_checker(room_schedule){
  //   if (!isNaN(room_schedule)){
  //     let ndate = new Date();
  //     let yesterday = new Date(now.getYear(), now.getMonth(), now.getDate() - 1);
  //     if (yesterday > room_schedule) {
  //       alert("現在より過去の日付は設定できませんs")
  //       $room_schedule.val("");
  //     }
  //     return
  //   }else{
  //     return ""
  //   }
  // }
  
  // const $room_schedule = $('#room_schedule');
  // let room_schedule_date = new Date( Date.parse($room_schedule.val()))
  // $('#quote_get_schedule').text(get_schedule_text(room_schedule_date))

  // $room_schedule.on('keyup change',function(){
  //   room_schedule_date = new Date( Date.parse($room_schedule.val()))
  //   schedule_checker(room_schedule_date);
  //   $('#quote_get_schedule').text(get_schedule_text(room_schedule_date))
  // });



  //最終金額
  var $room_fixed_price = $('#room_fixed_price');
  $('#quote_get_fixed_price').text($room_fixed_price.val())
  $room_fixed_price.on('keyup change',function(){
    $('#quote_get_fixed_price').text($room_fixed_price.val())
  });
  //最終報告
  var $room_report = $('#room_report');
  $('#quote_get_quotation_description').text($room_report.val())
  $room_report.on('keyup change',function(){
    $('#quote_get_quotation_description').text($room_report.val())
  });


    // numberのmaxlehgthはうまく機能しないらしいのでここで。
    $('.quotation_max9').on('input', function(e) { 
      var value = $(this).val()
      if (value > 9) {
          $(this).val(value.slice(0, 9))
      }
  })

});

$(document).ready(function() {
  var formInput = $('.load-alert');
  var formIsDirty = false;

  formInput.on('input', function() {
    formIsDirty = true;
  });

  $(window).on('beforeunload', function() {
    if (formIsDirty) {
      return 'このページを離れますか？入力中の内容がございます。';
    }
  });
});