$(document).on('click', '#fixBtn', function () {
    console.log("fix")
    $('#toFix').click();
});

//戻るボタンで遷移するともう一度送信できてしまうのでリロード
window.onpageshow = function(event) {
	if (event.persisted) {
		 window.location.reload();
	}
};


$('.show_loading').on('click', function(){
    // loading画面表示
    $('#loading').removeClass('hide');
});


//modal向け
//見積もり金額
let $room_quotation = $('#room_quotation');
$('#quote_get_quotation').text($room_quotation.val());
$room_quotation.on('keyup change',function(){
    let quotation_price = Number($room_quotation.val()).toLocaleString();
    $('#quote_get_quotation').text(quotation_price);
});
//備考
let $room_quotation_description = $('#room_quotation_description');
$('#quote_get_quotation_description').text($room_quotation_description.val())
$room_quotation_description.on('keyup change',function(){
    $('#quote_get_quotation_description').text($room_quotation_description.val())
});