$(document).on('change', '.imageFilePreview', function(e) {
    var dataid = $(this).attr("data-id")
    $(".image-preview"+dataid).removeClass("hide");
    var fileReader = new FileReader();
	fileReader.onload = (function() {
		document.getElementById('preview'+dataid).src = fileReader.result;
	});
	fileReader.readAsDataURL(e.target.files[0]);
});

$(document).on('change', '.ajaxFileField', function(e) {
    var dataid = $(this).attr("data-id")
    $(".image-preview"+dataid).removeClass("hide");
    $("#loading-wrapper").removeClass('hide');
    var fileReader = new FileReader();
	fileReader.onload = (function() {
		document.getElementById('preview'+dataid).src = fileReader.result;
        if (dataid == 'Icon') {
            // id=baseIconを削除
            $("#baseIcon").hide();
        }
        $("#loading-wrapper").addClass('hide');
	});
	fileReader.readAsDataURL(e.target.files[0]);
    if (e.target.files[0].size > 0) {
        $('#imageSubmit'+dataid).click();
    }
});

$(document).on('change', '.fileFieldImage', function(e) {
    $("#loading-wrapper").removeClass('hide');
    var fileReader = new FileReader();
	fileReader.onload = (function() {
        $("#loading-wrapper").addClass('hide');
	});
	fileReader.readAsDataURL(e.target.files[0]);
    if (e.target.files[0].size > 0) {
        $('#imageSubmit').click();
    }
});

$(document).on('change', '.itemFileFieldImage', function(e) {
    $("#loading-wrapper").removeClass('hide');
    var fileReader = new FileReader();
	fileReader.readAsDataURL(e.target.files[0]);
    if (e.target.files[0].size > 0) {
        $('#imageSubmit').click();
    }
});

//メッセージ画像の追加検知
$(document).on('change', '.messageFileFieldImage', function(e) {
    var fileReader = new FileReader();
    fileReader.onload = (function (e) {
        document.getElementById('imagePreview').innerHTML = ""
        document.getElementById('imagePreview').innerHTML += '<img src="' + e.target.result + '">';
        if (document.getElementById('message_image').value) {
            document.getElementById('message-button').classList.remove('disabled')
        }
        $("#imagePreview").removeClass("hide")
    });
	fileReader.readAsDataURL(e.target.files[0]);
});
// メッセージ送信後プレビュー非表示
$(document).on('click', '#message-button', function () {
    $("#imagePreview").addClass("hide");
});
// メッセージ送信後プレビュー非表示(official_room)
$(document).on('click', '#speaker_submit', function () {
    $("#imagePreview").addClass("hide");
});




//依頼画像と店舗画像兼用
$(document).on('click', '.imageInputBtn', function () {
    $('.fileFieldImage').click();
});
$(document).on('click', '.itemImageInputBtn', function () {
    $('.itemFileFieldImage').click();
});

$(document).on('click', '.iconImageInputBtn', function () {
    $('.iconFileFieldImage').click();
});

$(document).on('click', '.storeImageInputBtn', function () {
    $('#sampleImgList').empty();
    $('.fileFieldImage').click();
});

//免許・資格画像用
$(document).on('click', '.licenceImageInputBtn', function () {
    $('.fileFieldImage').click();
});
//免許・資格画像用2
$(document).on('click', '.licenceImageInputBtn2', function () {
    $('.fileFieldImage2').click();
});

$(document).on('click', '.messageFileFieldBtn', function () {
    $('.messageFileFieldImage').click();
});

//依頼画像削除
$(document).on('click', '.imgDelete', function () {
    var target = $(this).parent()
    var id = $(this).attr("data-id")
    console.log(id);
    $.ajax({
        method: 'PUT',
        url: '/item_images/destroy/'+id,
        data: {
            id: id
        }
    }).done(function (data) {
        // 成功処理
        target.remove()
        var image_ids = []
        $(".imgList").each(function(i, o){
            var id = $(o).find("img").attr("data-id")
            image_ids.push(id)
        });
        $("#image_ids").val( image_ids.join() )
    }).fail(function () {
        // 失敗処理
    });
});

//店舗画像削除
$(document).on('click', '.storeImgDelete', function () {
    if (window.confirm("削除しますか?")) {
        var target = $(this).parent()
        var id = $(this).attr("data-id")
        console.log(id);
        $.ajax({
            method: 'PUT',
            url: '/store_images/destroy/'+id,
            data: {
                id: id
            }
        }).done(function (data) {
            // 成功処理
            target.remove()
            var image_ids = []
            $(".imgList").each(function(i, o){
                var id = $(o).find("img").attr("data-id")
                image_ids.push(id)
            });
            $("#image_ids").val( image_ids.join() )
        }).fail(function () {
            // 失敗処理
        });
    };
});