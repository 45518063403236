console.log("form.js")
// $(function(){
//     // 訪問希望日時フォーム表示/非表示切り替え
//     $('.entryChange1').on("click", function(){
//         entryChange1()
//     });
//     function entryChange1(){
//         date_radio = document.getElementsByName('item[tmp6]') 
//         if(date_radio[0].checked) {
//         console.log("訪問希望日時あり")
//         $("#item_tmp6_no").parent().removeClass("checked");
//         $("#item_tmp6_yes").parent().addClass("checked");
//         $('.preferred_date_form').fadeIn();
//         $('.preferred_date_form').removeClass("hide");

//         }else{
//         $("#item_tmp6_yes").parent().removeClass("checked");
//         $("#item_tmp6_no").parent().addClass("checked");
//         $('.preferred_date_form').addClass("hide");
//         $('.preferred_date_form').fadeOut();
//         //   訪問希望日時なしを選択すると日時を空にする
//         $('#item_first_preferred_date').val('');
//         $('#item_second_preferred_date').val('');
//         $('#item_third_preferred_date').val('');
//         }
//     }


//     // 住所などの詳細入力部分のchecker呼び出し
//     $('.required_form').on("input change", function(){
//         requirement_check()
//         console.log("required_form")
//     });

//     // 住所などの詳細入力部分のchecker呼び出し（第一希望日用）
//     $('#item_first_preferred_date').on("input change", function(){
//         requirement_check()
//         console.log("訪問希望日時")
//     });
//     // $('.fuyouhin_requirement').on("input change", function(){
//     //     if(fuyouhin_requirement_check() && requirement_check()){
//     //         // flag_activiation(flag)
//     //         console.log("合格")
//     //     }
//     // });

    
//     // $('.ihinseiri_requirement').on("input change", function(){
//     //     ihinseiri_requirement_check()
//     // });
//     function checker_example(){
//         $('.fuyouhin_requirement').on("input change", function(){
//             console.log("checker起動")
//             if(fuyouhin_requirement_check() && requirement_check()){
//                 // flag_activiation(flag)
//                 console.log("合格")
//             }
//         });
//     }
//     function fuyouhin_requirement_check(){
//         let flag = true;
//         // 不用品の量はどの程度ですか？にチェックが入っているか
//         plan_radio = document.getElementsByName('item[plan]')
//         if(plan_radio.length && !(plan_radio[0].checked) && !(plan_radio[1].checked)){
//             flag = false;
//         }
//         return flag;

//     }
//     function ihinseiri_requirement_check(){
//         let flag = true;
//         if($("input[name='item[plan]']:checked").length == 0){
//             flag = false;
//         }
//         return flag;
//     }
//     function requirement_check() {
//         //step3のアンサーのチェッカー
//         let flag = true;
//         //必須項目をひとつずつチェック
//         $('.required_form').each(function(index,element) {
//             //もし必須項目が空なら
//             if ($(element).val() == "") {
//                 flag = false;
//                 return false;
//             }
//         });
//         // 不用品の量はどの程度ですか？にチェックが入っているか
//         // if(flag){
//         //     plan_radio = document.getElementsByName('item[plan]')
//         //     if(plan_radio.length && !(plan_radio[0].checked) && !(plan_radio[1].checked)){
//         //         flag = false;
//         //         return false;
//         //     }
//         // }
//         // if(flag){
//         //     if($("input[name='item[plan]']:checked").length == 0){
//         //         flag = false;
//         //     }
//         // }

//         // 訪問希望日時にチェックが入っているか
//         if(flag){
//             date_radio = document.getElementsByName('item[tmp6]')
//             if(!(date_radio[0].checked) && !(date_radio[1].checked)){
//                 flag = false;
//                 return false;
//             }
//         }

//         // 訪問希望日時がある場合は第一希望日時が必須
//         if(flag){
//         // ラジオボタン取得
//             date_radio = document.getElementsByName('item[tmp6]') 
//         // 希望ありかつ第一希望が空でfalse
//             if(date_radio[0].checked && $('#item_first_preferred_date').val() == ""){
//                 flag = false
//             }
//         }

//         // メールアドレスに問題がないかチェック
//         if(flag){
//             let $email_check = $('.email_check');
//             if ($email_check.length){
//                 if(email_check($email_check.val())) {
//                     $('#email_format_alert').addClass("hide");
//                 } else {
//                     $('#email_format_alert').removeClass("hide");
//                     flag = false;
//                     //returnさせない
//                 }
//             }
//         }
//         flag_activiation(flag);
//     }
//     function flag_activiation(flag){
//         if (flag) {
//             //送信ボタンを有効化・アラート非表示
//             $('.attention').fadeOut();
//             $('#simple_form_submit').removeClass("gray");
//             $('#simple_form_submit').removeClass("disabled");
//         } else {
//             // 送信ボタンを無効化・アラート表示
//             $('.attention').fadeIn();
//             $('#simple_form_submit').addClass("gray");
//             $('#simple_form_submit').addClass("disabled");
//         }
//     }
//     //メールアドレスチェッカー
//     function email_check(address){
//         let check = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//         if ( check.test( address ) ) {
//             return true;
//         } else {
//             return false;
//         }
//     }
// });